import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 1rem 0;
  background-color: gray;
  overflow: hidden;
  max-width: 100%;
  min-height: ${({ width }) => `${width}px`};
  display: grid;
  place-items: center;
  border: 0.25rem solid gray;

  > * {
    height: ${({ width }) => `${width}px`};
  }
`

const ProjectLogo = ({ imageData, imageSrc, alt, size }) => (
  <Wrapper width={size}>
    {imageData ? (
      <GatsbyImage
        image={imageData}
        imgStyle={{ height: `${size}px` }}
        alt={alt}
      />
    ) : (
      <img alt={alt} src={imageSrc} />
    )}
  </Wrapper>
)

export default ProjectLogo
